import Image from "shared/Image";
import Link from "next/link";
import { formatDistanceToNow } from "date-fns";

import ReadingTime from "shared/ReadingTime";

export default function FeaturedPost({ post }) {
  return (
    <div className="relative rounded-2xl" data-cy="FeaturedPost">
      <div className="bg-gradient-to-tl from-gray-900 rounded-2xl w-full h-auto">
        <Image
          src={post.feature_image}
          width={768}
          height={468}
          layout="responsive"
          className="object-cover mix-blend-overlay rounded-2xl"
          alt={post.title}
        />
      </div>
      <div className="absolute top-0 left-0 bg-gray-900/50 text-white font-medium uppercase py-2 px-1 rounded-t-2xl w-full shadow-xl">
        <div className="ml-4 flex items-center justify-center">
          Featured Article
          <span className="mx-1">&bull;</span>
          <span>
            {formatDistanceToNow(new Date(post.published_at), {
              addSuffix: true,
            })}
          </span>
          <span className="mx-1">&bull;</span>
          <ReadingTime post={post} className="text-white" />
        </div>
      </div>
      <h2
        className="absolute top-0 left-0 text-white text-xl md:text-5xl font-black uppercase tracking-tighter leading-none my-8 text-center flex h-full items-center"
        style={{ textShadow: "2px 2px 2px #222" }}
      >
        <Link href={post.slug}>
          <a className="flex flex-col items-center justify-center hover:text-blue-100">
            {post.title} <br />{" "}
            <span className="text-xl md:text-3xl flex items-center justify-center">
              <span className="mr-2">By</span>

              {post.primary_author.name}
            </span>
            {post.primary_author.profile_image && (
              <Image
                src={post.primary_author.profile_image}
                width={44}
                height={48}
                className="rounded-full"
                alt={post.primary_author?.name}
              />
            )}
          </a>
        </Link>
      </h2>
    </div>
  );
}
