import axios from "axios";
import { useSWRInfinite } from "swr";
import useScrollPosition from "./useScrollPosition";

function fetcher(url) {
  return axios
    .get(`${process.env.NEXT_PUBLIC_GHOST_API_URL}/ghost/api/v3/content/${url}`, {
      params: { key: process.env.NEXT_PUBLIC_GHOST_API_KEY },
    })
    .then(({ data }) => data);
}

function getKey(pageIndex, previousPageData) {
  if (previousPageData && !previousPageData.posts) {
    return null;
  }
  if (pageIndex === 0) {
    return `/posts?page=1&include=tags,authors`;
  }
  if (previousPageData?.meta?.pagination?.next) {
    return `/posts?page=${previousPageData?.meta?.pagination?.next}&include=tags,authors`;
  }
  return null;
}

export default function useIndexPage({ posts }) {
  const { data, setSize, size, error } = useSWRInfinite(getKey, fetcher, {
    initialData: [{ posts: posts, meta: { pagination: { next: 2 } } }],
  });
  const handleSetSize = () => {
    // use function state because of they way we are calling this function inside a hook
    // we want to ensure we have the latest state here.
    setSize((prevState) => prevState + 1);
  };
  useScrollPosition({ trigger: { cb: handleSetSize, value: 75 } });
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");
  return {
    data,
    setSize,
    isLoadingMore,
    size,
  };
}
