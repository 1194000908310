import NextImage, { ImageProps } from "next/image";
import { buildImgProxyUrl } from "utils";

export default function Image(props: ImageProps) {
  const { src, ...rest } = props;
  let withCmsSrc = src;
  if (withCmsSrc && typeof withCmsSrc === "string") {
    if (!withCmsSrc.startsWith("http") && !withCmsSrc.startsWith("www")) {
      withCmsSrc = `${process.env.NEXT_PUBLIC_GHOST_API_URL}${src}`;
    }
    // inject placeholder and blurDataURL from imgproxy api
    return (
      <NextImage
        src={
          buildImgProxyUrl({
            url: withCmsSrc,
            width: props.width as number,
            height: props.height as number,
          }) as never
        }
        placeholder="blur"
        blurDataURL={
          buildImgProxyUrl({
            url: withCmsSrc,
            width: 10,
            height: 10,
          }) as never
        }
        {...rest}
      />
    );
  }

  //@ts-ignore
  return <NextImage src={withCmsSrc} {...rest} />;
}
