import { useState } from "react";
import useScrollPosition from "hooks/useScrollPosition";

export default function ScrollToTopBtn() {
  const [visible, setVisible] = useState(false);
  const handleSetVisible = () => setVisible(!visible);
  useScrollPosition({ trigger: { cb: handleSetVisible, value: 25 } });
  if (visible) {
    return (
      <div className="flex justify-end w-full sticky bottom-2 pointer-events-none">
        <button
          onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
          className="h-12 w-12 md:h-16 md:w-16 mb-4 rounded-full bg-gradient-to-tr  from-primary-900 to-primary-400 flex items-center justify-center text-white mx-4 pointer-events-auto"
          title="Scroll to top"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 11l7-7 7 7M5 19l7-7 7 7"
            />
          </svg>
        </button>
      </div>
    );
  }
  return null;
}
