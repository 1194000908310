import Meta from "shared/Meta";
import Nav from "shared/Nav";
import Footer from "shared/Footer";
import FeaturedPost from "shared/FeaturedPost";
import PostCard from "shared/PostCard";
import Podcast from "shared/Podcast";
import useIndexPage from "hooks/useIndexPage";
import { api } from "lib/api";
import ScrollToTopBtn from "shared/ScrollToTopBtn";

interface IndexPageProps {
  settings: any;
  posts: any;
}

export default function IndexPage({ settings, posts }: IndexPageProps) {
  const { data, isLoadingMore, setSize, size } = useIndexPage({ posts });
  return (
    <>
      <Meta settings={settings} seoImage={settings.logo} />
      <Nav settings={settings} />
      <div className="container mx-auto mt-8 md:mt-16">
        <div className="flex items-center justify-between">
          <h1
            className="tracking-tighter leading-none text-4xl md:text-6xl uppercase font-black px-4 md:px-8"
            style={{ color: settings?.accent_color || "#222" }}
          >
            {settings.title}
          </h1>
        </div>
        <hr className="my-4" />
        <div className="flex items-center justify-center mb-4 mx-4 m-2 max-w-[100%]">
          <ins
            className="adsbygoogle"
            style={{ display: "inline-block", width: 728, height: 90 }}
            data-ad-client="ca-pub-6557786462262398"
            data-ad-slot="4918911610"
          ></ins>
        </div>
        <div className="md:flex md:items-start mb-8">
          <div className="md:w-3/4 px-4 md:px-8">
            <div className="grid grid-cols-1 mb-4">
              {posts
                ?.filter((post) => post.featured)
                .map((post) => (
                  <FeaturedPost post={post} key={post.id} />
                ))}
            </div>
          </div>
          <div className="md:w-1/4 px-4 md:px-8">
            <Podcast />
            <div className="m-4">
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6557786462262398"
                data-ad-slot="3946942423"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </div>
          </div>
        </div>

        <div className="px-4 md:px-8">
          <h2 className="text-3xl font-black my-4 text-blue-900">More For You</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 mb-8">
            {[...data]
              .map((item) => item.posts)
              .flat(1)
              ?.filter((post) => post.featured === false)
              .map((post) => (
                <div className=" mb-4 mr-2" key={post.id}>
                  <PostCard post={post} />
                </div>
              ))}
          </div>
          <button
            disabled={isLoadingMore}
            onClick={() => setSize(size + 1)}
            className="flex items-center justify-center w-full bg-gradient-to-tr  from-primary-900 to-primary-400 text-gray-200 hover:text-white px-4 py-3 rounded shadow-xl font-medium mb-16 md:mb-32"
          >
            <span className="mr-2">Load More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
          </button>
        </div>
      </div>
      <ScrollToTopBtn />
      <Footer settings={settings} />
    </>
  );
}

export async function getStaticProps() {
  const settings = await api.getSettings();
  const posts = await api.getFrontPagePosts();
  return {
    props: {
      settings,
      posts,
    },
  };
}
