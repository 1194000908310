import Image from "shared/Image";
import Link from "next/link";
import ReadingTime from "shared/ReadingTime";
import { formatDistanceToNow } from "date-fns";

export default function PostCard({ post }) {
  return (
    <div
      key={post.slug}
      className="relative rounded-xl object-contain shadow-2xl bg-white"
      data-cy="PostCard"
    >
      <div className=" bg-gradient-to-tr from-gray-900 rounded-t-xl">
        {post.feature_image && (
          <Image
            src={post.feature_image}
            width={750}
            height={400}
            className="mix-blend-overlay rounded-t-xl bg-gray-900/50 object-cover"
            alt={post.title}
          />
        )}
      </div>
      <div
        className="absolute top-0 left-0 text-center md:left-8 text-white text-2xl md:text-4xl font-black tracking-tight leading-none my-4"
        style={{ textShadow: "2px 2px 2px #222" }}
      >
        <Link href={post.slug}>
          <a className="hover:text-blue-100">{post.title}</a>
        </Link>
      </div>
      <div className="p-4 bg-white text-gray-900 md:text-xl font-medium tracking-tighter leading-none md:min-h-[150px]">
        <Link href={post.slug}>
          <a>
            {post.meta_description ||
              `${
                post?.excerpt
                  ? post?.excerpt?.substring(0, 150)
                  : post?.custom_excerpt?.substring(0, 150)
              }...`}
          </a>
        </Link>
      </div>
      <div className="flex items-center w-full bg-white">
        {post.authors?.map((author) => (
          <div key={author.id} className=" py-2 px-4 flex items-center">
            {author.profile_image && (
              <Image
                src={author.profile_image}
                width={44}
                height={48}
                alt={author.name}
                className="rounded-full"
              />
            )}
            <div className="ml-8 text-gray-500 font-medium text-sm">
              <div>{author.name}</div>
              <span>
                {formatDistanceToNow(new Date(post.published_at), {
                  addSuffix: true,
                })}
              </span>
              <span className="mx-1">&bull;</span>
              <ReadingTime post={post} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
