export default function Podcast() {
  return (
    <div data-cy="Podcast">
      <div className="text-3xl font-thin pb-4 border-b-2 border-blue-400">Latest Podcasts</div>
      <iframe
        title="The Speckled Truth Podcast"
        id="multi_iframe"
        src="https://www.podbean.com/media/player/multi?playlist=http%3A%2F%2Fplaylist.podbean.com%2F5561292%2Fplaylist_multi.xml&vjs=1&kdsowie31j4k1jlf913=c2fed92fe7539e2acf91e79fcdc700a22faeadf6&size=430&skin=3&episode_list_bg=%23ffffff&bg_left=%23fffcff&bg_mid=%23ffffff&bg_right=%23ffffff&podcast_title_color=%23273692&episode_title_color=%23273692&auto=0&share=0&fonts=Helvetica&download=0&rtl=0&show_playlist_recent_number=10&pbad=1"
        frameBorder="0"
        scrolling="no"
        width="100%"
        height="432"
        allowFullScreen
      ></iframe>
    </div>
  );
}
