import { useEffect } from "react";
import { useCallback, useState, useRef } from "react";

interface UseScrollPositionProps {
  trigger?: {
    /**Callback to be called whent the value is hit this will only trigger scrolling down */
    cb: () => void;
    /**Value is the percentage number u want to trigger the callback on 0-100 */
    value: number;
  };
}

const getDocHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
};

export default function useScrollPosition(props?: UseScrollPositionProps) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const ref = useRef(null);
  const handleScroll = useCallback(
    (evt) => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const docHeight = getDocHeight();
      const totalDocScrollLength = docHeight - windowHeight;
      const sp = Math.floor((scrollTop / totalDocScrollLength) * 100);
      if (ref.current && sp > ref.current) {
        if (props?.trigger) {
          if (sp === props.trigger.value) {
            props.trigger.cb();
          }
        }
      }
      setScrollPosition(sp);
      ref.current = sp;
    },
    [setScrollPosition]
  );

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollPosition;
}
